export const m = {
    // 顶部参数
    searchTxt: 'Please enter search keywords',
    // 底部参数
    footerLinkName: 'LINKS',
    footerLink: [{
            name: 'Ministry of Foreign Affairs of the PRC',
            url: 'https://www.fmprc.gov.cn/eng/'
        },
        {
            name: 'Ministry of Commerce of the PRC',
            url: 'http://english.mofcom.gov.cn/'
        },
        {
            name: 'Belt and Road Portal',
            url: 'https://eng.yidaiyilu.gov.cn/'
        },
        {
            name: 'GCIGC',
            url: 'http://www.gsjtw.cc/'
        },
    ],
    footerCompanyName: 'China Gansu International Economic and Technical Cooporation Co., Ltd.',
    footerLeft: `<li>China Gansu International Economic and Technical Cooporation Co., Ltd.</li>
    <li>Website：http://www.cgico.cn &nbsp;&nbsp;&nbsp;&nbsp; E-mail Box：cgicop@cgicop.com</li>
    <li>Fax：0086-931-2658644</li>
    <li>Address：Xijin West Road, No. 575，Qi Lihe District， Lanzhou City, Gansu, China</li> 
    <li>Phone：0086-0931-2216431</li> 
    <li>
      <span>备案编号：
        <a href="https://beian.miit.gov.cn/" target="_blank">陇ICP备11000312号-1</a>&nbsp;&nbsp;甘公网安备：
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=62010302000333" target="_blank">62010302000333</a>
        </br>
        All texts, pictures, audios and videos on this website are copyrighted by CGICO. The style of this website is protected by copyright, and no unauthorized reproduction, mirroring or imitation is allowed. Violators will be prosecuted.
      </span>
    </li>`,
    footerRight: `Scan Concerned about the official account of China Gansu International`,
    // 标题参数
    titleNewsSec01: 'News Center',
    titleNewsSec01Sub: [{
            id: 1,
            title: 'CGICO News'
        },
        {
            id: 2,
            title: 'Industry News'
        },
        {
            id: 3,
            title: 'GCIGC News'
        },
    ],
    titleNewsSec01SubMore: {
        txt: '更多',
        url: '/list/01800001'
    },
    titleNewsSec02: 'Projects',
    titleNewsSec02SubMore: {
        txt: '更多',
        url: '/list/01920001'
    },
    titleNewsSec03: 'CGICO & I',
    titleNewsSec03SubMore: {
        txt: '更多',
        url: '/listPicTwo/01830005'
    },
    listPageOption: 'Current position',
    listPageHome: 'Home',
    listPageDetail: 'News details',
    detailTime: 'Publish time',
    detailView: 'Page view',
    detailCount: 'Count',

    webId: '94f7e6e5-ed90-4657-b680-d78b6537bb21',
}