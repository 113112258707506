import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui';

import "swiper/dist/css/swiper.css";
// import "path/to/swiper.min.js"
// import "path/to/swiper.animate.min.js"
import "./assets/css/public.css"
import "./assets/css/style.css"

import wow from 'wowjs'

import 'animate.css'

Vue.prototype.$wow = wow

Vue.use(ElementUI);

// 引入视频插件
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-flash'
import 'videojs-contrib-hls'
Vue.prototype.$video = Video

// 引入dayjs
import dayjs from "dayjs"
Vue.prototype.dayjs = dayjs

// 全局id
Vue.prototype.webId = '688336EC-033F-4C10-8573-726BFE0C0AE5'
Vue.prototype.webIdEn = '94f7e6e5-ed90-4657-b680-d78b6537bb21'
Vue.prototype.webIdGroup = 'BEE8AB69-65E3-4161-ACF7-0E09E4028BB8'  //集团

// 引入vue-i18n
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: 'zh-CN', // 语言标识
    messages: {
        'zh-CN': require('./lang/zh'), // 通过require引入中文语言包
        'en-US': require('./lang/en') // 通过require引入英文语言包
    }
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')